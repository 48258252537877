import { getFunctions, httpsCallable } from "firebase/functions";
import { createGen } from "./MakeAIGenko";

export const createIsMovieShort = (
  flag,
  setIsShort,
  movieSetting,
  setMovieSetting
) => {
  setIsShort(flag);
  const valuestring = flag ? "1" : "2";
  setMovieSetting({
    ...movieSetting,
    set4: valuestring,
  });
  //ここを動かすと暴発します．絶対に動かしてはいけない，理由は分からない．
  // createMovieSettingText({
  //   ...movieSetting,
  //   set4: valuestring,
  // });
};

export const createRapInput = async (
  nextEditDatas,
  editDatas,
  setFourTextShow,
  setMovieStage,
  autoCreateMovie,
  setAutoCreateMovie,
  stopAuto,
  movieStage
) => {
  let useEditDatas;
  if (nextEditDatas) {
    useEditDatas = nextEditDatas;
  } else {
    useEditDatas = editDatas;
  }
  //console.log(useEditDatas);
  try {
    let chatGPTMessage = "";
    chatGPTMessage = chatGPTMessage + "「" + useEditDatas.claim + "」";
    chatGPTMessage =
      chatGPTMessage +
      "という意見について，\n2人による会話を書いてください\n\n";
    chatGPTMessage = chatGPTMessage + "「" + useEditDatas.claim + "」";
    chatGPTMessage = chatGPTMessage + "に対する賛成意見は\n";

    useEditDatas.infoagree.map((item) => {
      chatGPTMessage = chatGPTMessage + "「" + item.data.claim + "」\n";
    });
    chatGPTMessage = chatGPTMessage + "等がある．\n\n";
    chatGPTMessage = chatGPTMessage + "「" + useEditDatas.claim + "」";
    chatGPTMessage = chatGPTMessage + "に対する反対意見は\n";

    useEditDatas.infodisagree.map((item) => {
      chatGPTMessage = chatGPTMessage + "「" + item.data.claim + "」\n";
    });

    chatGPTMessage = chatGPTMessage + "等がある．\n\n";

    chatGPTMessage += "下記の2人による会話を10会話ほど書いてください。\n\n";

    chatGPTMessage += "ーーーーーーーーーーーーーーーーーーーー\n";
    chatGPTMessage += "山内さん\n";
    chatGPTMessage +=
      "普段はしっかり者で気遣い上手だが、気を許したら気ままに絡んでくる。\n";
    chatGPTMessage +=
      "お散歩好きで、晴れた日は厚底スニーカーで気の向くままに歩いている。\n";
    chatGPTMessage += "お酒が好きだけど弱く、たまにほろ酔いで散歩している。\n";
    chatGPTMessage +=
      "迷子のところを長田に助けてもらって以来、すっかり懐いている。\n\n";

    chatGPTMessage += "長田さん\n";
    chatGPTMessage += "デスクワークを淡々とこなすサラリーマン。\n";
    chatGPTMessage += "肩と首のコリは慢性的な悩み。\n";
    chatGPTMessage +=
      "たまにぼそっと妙なことを言う抜け感があり、それが周囲を和ませる。\n";
    chatGPTMessage +=
      "山内からは「おさポン、散歩行こ〜」といつも誘われている。\n";
    chatGPTMessage += "街中の小さなオブジェ等を発見するのが得意。\n";
    chatGPTMessage += "ーーーーーーーーーーーーーーーーーーーー\n\n";

    chatGPTMessage += "（発言内容）にかぎかっこは記載しないでください\n";
    chatGPTMessage += "話し手・聞き手は山内・長田の2種類です\n";
    chatGPTMessage +=
      "片方が話し手，片方が聞き手となって交互に会話します。\n\n";

    chatGPTMessage += "表情は普通・笑顔・呆れの3種類があります\n";
    chatGPTMessage += "姿勢は棒立ち・指さしの2種類があります\n\n";

    chatGPTMessage += "表情と姿勢は偏らないように使ってください\n";

    chatGPTMessage +=
      "文章が長くなりすぎないよう20文字以内に必ず句読点を入れてください。\n";

    chatGPTMessage += "フォーマットは下記としてください\n";
    chatGPTMessage +=
      "【話し手：山内 - 棒立ち_普通 / 聞き手：長田 - 棒立ち_普通】\n";
    chatGPTMessage += "（発言内容）\n";
    chatGPTMessage +=
      "【話し手：長田 - 指さし_呆れ / 聞き手：山内 - 棒立ち_笑顔】\n";
    chatGPTMessage += "（発言内容）\n";

    setFourTextShow(chatGPTMessage);
    setMovieStage({ ...movieStage, 2: "完" });

    //自動生成フラグ
    if (autoCreateMovie.rapInputCreate) {
      setAutoCreateMovie({
        ...autoCreateMovie,
        rapInputCreate: false,
      });
      //useEffectが動く
    }

    return chatGPTMessage;
  } catch (error) {
    alert(error + "\nエラー#0006、二度と押さないでください");
    stopAuto();
    return false;
  }
};

export const createRap = async (
  chatGPTMessage,
  setFiveTextShow,
  setMovieStage,
  autoCreateMovie,
  setAutoCreateMovie,
  stopAuto,
  movieStage
) => {
  setFiveTextShow("作成中");
  try {
    const functions = getFunctions();
    const messageCreate = httpsCallable(functions, "messageCreate");
    messageCreate({ message: chatGPTMessage }).then((result) => {
      if (result.data) {
        setFiveTextShow(result.data.aimessage);
        setMovieStage({ ...movieStage, 3: "完" });

        //自動生成フラグ
        if (autoCreateMovie.rapCreate) {
          setAutoCreateMovie({
            ...autoCreateMovie,
            rapCreate: false,
          });
          //useEffectが動く
        }

        return result.data.aimessage;
      } else {
        setFiveTextShow("エラー");
        return false;
      }
    });
  } catch (error) {
    alert(error + "\nエラー#0001、二度と押さないでください");
    stopAuto();
    setFiveTextShow("エラー");
    return false;
  }
};
export const createExplainvideoInput = async (
  nextEditDatas,
  editDatas,
  setMovieStage,
  setExplainvideoInput,
  stopAuto,
  movieStage
) => {
  let useEditDatas;

  if (nextEditDatas) {
    useEditDatas = nextEditDatas;
  } else {
    useEditDatas = editDatas;
  }
  //console.log(useEditDatas);
  try {
    let chatGPTMessage = "";
    chatGPTMessage = chatGPTMessage + useEditDatas.claim;
    chatGPTMessage = chatGPTMessage + "に対する賛成意見は";

    useEditDatas.infoagree.map((item) => {
      chatGPTMessage = chatGPTMessage + "、「" + item.data.claim + "」";
    });
    chatGPTMessage = chatGPTMessage + "等がある．";

    chatGPTMessage = chatGPTMessage + useEditDatas.claim;
    chatGPTMessage = chatGPTMessage + "に対する反対意見は";

    useEditDatas.infodisagree.map((item) => {
      chatGPTMessage = chatGPTMessage + "、「" + item.data.claim + "」";
    });
    chatGPTMessage = chatGPTMessage + "等がある．";
    chatGPTMessage =
      chatGPTMessage +
      "\n\nこれらを踏まえてかわいい女子大生みたいなツイートを100文字以内で作ってください．＃は記載しないでください．";
    chatGPTMessage =
      chatGPTMessage +
      "ただし，下記のような設定のキャラクターになりきって文章を作成してください．";
    chatGPTMessage = chatGPTMessage + "\n・人間の女の子";
    chatGPTMessage = chatGPTMessage + "\n・大学生";
    chatGPTMessage = chatGPTMessage + "\n・#は使わない";
    chatGPTMessage = chatGPTMessage + "\n・タグはつけない";
    chatGPTMessage = chatGPTMessage + "\n・ツイートは100文字";

    setMovieStage({ ...movieStage, 8: "完" });
    setExplainvideoInput(chatGPTMessage);

    return chatGPTMessage;
  } catch (error) {
    alert(error + "\nエラー#0002、二度と押さないでください");
    stopAuto();
    return false;
  }
};

export const createExplainvideo = async (
  chatGPTMessage,
  setExplainvideo,
  setMovieStage,
  createExplain,
  autoCreateMovie,
  setAutoCreateMovie,
  stopAuto,
  claim,
  explainvideoInput,
  lonpaid,
  movietypetext,
  movieStage
) => {
  // setFiveTextShow("作成中");
  setExplainvideo("作成中");
  try {
    const functions = getFunctions();
    const messageCreate = httpsCallable(functions, "messageCreate");
    messageCreate({ message: chatGPTMessage }).then((result) => {
      if (result.data) {
        // setFiveTextShow(result.data.aimessage);
        setMovieStage({ ...movieStage, 9: "完" });
        setExplainvideo(
          createExplain(result.data.aimessage, claim, lonpaid, movietypetext)
        );

        //自動生成フラグ
        if (autoCreateMovie.explainCreate) {
          setAutoCreateMovie({
            ...autoCreateMovie,
            explainCreate: false,
          });
          //useEffectが動く
        }
        return result.data.aimessage;
      } else {
        return false;
      }
    });
  } catch (error) {
    alert(error + "\nエラー#0003、二度と押さないでください");
    stopAuto();
    return false;
  }
};

export const createGen2 = async (
  message,
  setGenko,
  createIsMovieShort,
  setMovieStage,
  autoCreateMovie,
  setAutoCreateMovie,
  setIsShort,
  setMovieSetting,
  movieStage,
  nameDisagree,
  nameAgree,
  movieSetting
) => {
  try {
    const allgen = createGen(message, 20);
    setGenko(allgen);

    const strnum = 0; //何にすればいいのか分からないので一回0にする
    const shortMovieStrNum = 1; //何にすればいいのか分からないので一回1にする
    createIsMovieShort(
      strnum < shortMovieStrNum,
      setIsShort,
      movieSetting,
      setMovieSetting
    );
    setMovieStage({ ...movieStage, 4: "完" });
    //自動生成フラグ
    if (autoCreateMovie.genCreate) {
      setAutoCreateMovie({
        ...autoCreateMovie,
        genCreate: false,
      });
      //useEffectが動く
    }
    return allgen;
  } catch (error) {
    alert(
      error +
        "\nエラー#0010 文字列が想定した形式でないようです\n二度と押さないでください"
    );
    return false;
  }
};

export const createExplain = (inputtext, claim, lonpaid, movietypetext) => {
  let EText = "";
  EText = EText + "【AI vs AI】" + claim + "?\n";
  EText = EText + "------------------\n";
  EText = EText + inputtext;
  EText = EText + "\n";
  EText = EText + "\n";
  EText = EText + "#shorts #lonpa #lamact\n";
  EText = EText + "https://lonpa.net/Lonpa/" + lonpaid + "\n";
  EText = EText + "\n";
  EText = EText + "■ツール\n";
  EText = EText + "Remotion\n";
  EText = EText + "https://www.remotion.dev/\n";
  EText = EText + "\n";
  EText = EText + "AquesTalk10 ・ AqKanji2Koe\n";
  EText = EText + "https://www.a-quest.com/download.html\n";
  EText = EText + "\n";
  EText = EText + "立ち絵\n";
  EText = EText + "http://www.nicotalk.com/charasozai_yk.html\n";
  EText = EText + "\n";
  EText = EText + "BGM\n";
  EText = EText + movietypetext + "\n";
  EText = EText + "\n";
  return EText;
};

export const createMovieSettingText = (
  nextMovieSetting,
  lonpaid,
  setMovieSettingText,
  autoCreateMovie,
  setAutoCreateMovie
) => {
  let MSText = "movie,set1,set2,set3,set4,set5\n";
  MSText = MSText + lonpaid + ",";
  MSText =
    MSText +
    nextMovieSetting.set1 +
    "," +
    nextMovieSetting.set2 +
    "," +
    nextMovieSetting.set3 +
    "," +
    nextMovieSetting.set4 +
    "," +
    nextMovieSetting.set5;
  setMovieSettingText(MSText);

  if (autoCreateMovie.settingCreate) {
    setAutoCreateMovie({
      ...autoCreateMovie,
      settingCreate: false,
    });
    //useEffectが動く
  }
  return MSText;
};

//意見の文章をいい感じに改行するためのコード
export const maketext = (text, flag) => {
  if (text) {
    const locale = "ja-JP";

    const segmenter = new Intl.Segmenter(locale, { granularity: "word" });
    const splittedText = Array.from(segmenter.segment(text)).map(
      (s) => s.segment
    );

    let countwordlen = 0;
    let countlinelen = 1;
    let createText = "";
    splittedText.map((text) => {
      countwordlen = countwordlen + text.length;
      if (countwordlen > 8) {
        if (text.length == countwordlen) {
          createText = createText + text;
        } else {
          countwordlen = text.length;
          createText = createText + "\n" + text;
          countlinelen = countlinelen + 1;
        }
      } else {
        createText = createText + text;
      }
    });
    if (flag) {
      if (countlinelen < 3) {
        createText = "\n\n\n" + createText;
      } else if (countlinelen < 4) {
        createText = "\n\n" + createText;
      } else if (countlinelen < 5) {
        createText = "\n" + createText;
      }
    }

    return createText;
    //makeone(createText);
  }
};

export const createCsv = (
  inputgenko,
  setGenkocsv,
  setMovieStage,
  autoCreateMovie,
  setAutoCreateMovie,
  movieStage
) => {
  console.log("csv作成");
  let csvDatas = "index,chara,pose,pose2,text\n";
  inputgenko.map((item) => {
    // console.log(
    //   item.index +
    //     "," +
    //     item.chara +
    //     "," +
    //     item.pose +
    //     "," +
    //     item.pose2 +
    //     "," +
    //     item.text
    // );
    const csvOneData =
      item.index +
      "," +
      item.chara +
      "," +
      item.pose +
      "," +
      item.pose2 +
      "," +
      item.text +
      "\n";
    csvDatas = csvDatas + csvOneData;
  });
  setGenkocsv(csvDatas);
  setMovieStage({ ...movieStage, 5: "完" });
  if (autoCreateMovie.csvCreate) {
    setAutoCreateMovie({
      ...autoCreateMovie,
      csvCreate: false,
    });
    //useEffectが動く
  }
  return csvDatas;
};

export const createMovie = (lonpaid) => {
  try {
    const functions = getFunctions();
    const movieCreate = httpsCallable(functions, "movieCreate");
    movieCreate({ lonpaID: lonpaid }).then((result) => {
      if (result.data) {
        alert(error + "\n作成成功おめでとう");
        console.log("成功");
        return true;
      } else {
        console.log("失敗");
        return false;
      }
    });
  } catch (error) {
    alert(error + "\nエラー#0004、二度と押さないでください");
    return false;
  }
};

export const createLog = () => {
  console.log("createlog");
  try {
    const functions = getFunctions();
    const logCreate = httpsCallable(functions, "logtest");
    logCreate().then((result) => {
      console.log("send");
      if (result.data) {
        alert(error + "\n作成成功おめでとう");
        console.log("成功");
        return true;
      } else {
        console.log("失敗");
        return false;
      }
    });
  } catch (error) {
    alert(error + "\nエラー#0005、二度と押さないでください");
    return false;
  }
};
