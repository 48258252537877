import { Button } from "@mui/material";
import { serverTimestamp } from "firebase/firestore";
import React, { useState } from "react";

import { createDiscussTransaction } from "../../common/Helper/FirebaseTransactionHelper";

type Props = {
  bgColor: any;
  genko: any;
  editDatas: any;
  isDiscussUpdated: boolean;
  setIsDiscussUpdated: any;
};

const DiscussCreater: React.VFC<Props> = ({
  bgColor,
  genko,
  editDatas,
  isDiscussUpdated,
  setIsDiscussUpdated,
}: Props) => {
  const createDiscuss = () => {
    setIsDiscussUpdated(true);
    const nextgenko: any[] = [];

    genko.map((item: any, index: any) => {
      nextgenko.push({
        index: item.index,
        text: item.text,
        chara: item.chara,
        pose: item.pose,
        pose2: item.pose2,
        isAgree: item.chara === "yama",
      });
    });

    const nextinfoagree: any[] = [];
    editDatas.infoagree.map((item: any, index: any) => {
      nextinfoagree.push({
        data: {
          lonpaid: item.data.lonpaid,
          agree: item.data.agree,
          claim: item.data.claim,
          isDelete:
            item.data.isDelete !== undefined ? item.data.isDelete : false,
          votenum: item.data.votenum,
        },
      });
    });

    const nextinfodisagree: any[] = [];
    editDatas.infodisagree.map((item: any, index: any) => {
      nextinfodisagree.push({
        data: {
          lonpaid: item.data.lonpaid,
          agree: item.data.agree,
          claim: item.data.claim,
          isDelete:
            item.data.isDelete !== undefined ? item.data.isDelete : false,
          votenum: item.data.votenum,
        },
      });
    });

    const uploadData = {
      claim: editDatas.claim,
      infoagree: nextinfoagree,
      infodisagree: nextinfodisagree,
      genko: nextgenko,
      agree: editDatas.agree,
      disagree: editDatas.disagree,
      imageurl: editDatas.imageurl,
      movieImageURL: editDatas.movieImageURL,
      tag: editDatas.tag,
      ngram: editDatas.ngram,
    };
    createDiscussTransaction(editDatas.id, uploadData);
  };

  return (
    <>
      <br />
      {isDiscussUpdated ? (
        <>
          <Button
            style={{
              color: bgColor.text01.fontColor,
              backgroundColor: bgColor.back05,
            }}
            disabled
          >
            危険：クリエイト
          </Button>
        </>
      ) : (
        <>
          <Button
            style={{
              color: bgColor.text01.fontColor,
              backgroundColor: bgColor.back06,
            }}
            onClick={() => {
              if (editDatas.tag) {
                createDiscuss();
              }
            }}
          >
            危険：クリエイト
          </Button>
        </>
      )}
    </>
  );
};

export default DiscussCreater;
