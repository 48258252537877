import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { useStyles } from "../Styles";
import { Timestamp } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import * as yup from "yup";
import moment from "moment";

import { userApp } from "../common/firebase";

import { getUserPrivateVoteDescStartAfter } from "../common/Helper/FirebaseHelper";

import LodingModal from "./CommonModal/LodingModal";
import {
  VALIDATION_ERR_REQUIRED,
  VALIDAtION_INVALID_CHARACTER,
  VALIDATION_LONG_MAX_LENGTH,
  VALIDATION_USERNAME_MIN_LENGTH,
} from "../constants/validation";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NORMAL_CHARACTER } from "../constants/regex";
import Footer from "./components/Footer";

import { adminUid } from "../common/Config/AdminConfig";

const schema = yup.object({
  username: yup
    .string()
    .required(VALIDATION_ERR_REQUIRED)
    .min(4, VALIDATION_USERNAME_MIN_LENGTH)
    .max(64, VALIDATION_LONG_MAX_LENGTH)
    .matches(NORMAL_CHARACTER, VALIDAtION_INVALID_CHARACTER),
});

const Userpage = (props) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [uid, setUid] = useState("");
  const [upload, setupload] = useState(0);
  const [votedata, setVoteData] = useState({});
  const [lodingmodalopen, setLodingmodalopen] = useState(true);
  const [targetDateVote, setTargetDateVote] = useState("");
  const [adminFlag, setAdminFlag] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit: handleFormSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const parseAsMoment = (dateTimeStr) => {
    return moment.utc(dateTimeStr, "YYYY-MM-DDTHH:mm:00Z", "ja").utcOffset(9);
  };
  const picurl = [
    "https://firebasestorage.googleapis.com/v0/b/lonpa-5686d.appspot.com/o/category%2Fhome.jpg?alt=media&token=78540a61-565b-40ca-9343-2596543df212",
    "https://firebasestorage.googleapis.com/v0/b/lonpa-5686d.appspot.com/o/category%2Fsocial.jpg?alt=media&token=dbd1c1bd-44ab-4955-925c-67ec7dfb0dec",
    "https://firebasestorage.googleapis.com/v0/b/lonpa-5686d.appspot.com/o/category%2Fwork.jpg?alt=media&token=ee6854a0-03d7-486e-b8b2-75373548c42b",
    "https://firebasestorage.googleapis.com/v0/b/lonpa-5686d.appspot.com/o/category%2Fcountry.jpg?alt=media&token=9d9af185-d76d-41b3-abc2-6a758e61cd6f",
  ];
  const [help, sethelp] = useState(false);

  useEffect(() => {
    console.log("uE_Userpage");
    const f = async () => {
      onAuthStateChanged(getAuth(userApp), async (user) => {
        if (user) {
          if (typeof user.uid !== "undefined") {
            setEmail(user.email);
            setValue("email", user.email);
            setUid(user.uid);

            if (adminUid.includes(user.uid)) {
              setAdminFlag(true);
              await loadVoteHistory().catch((error) => {
                console.log(error);
              });
            }
            setLodingmodalopen(false);
          } else {
            window.scrollTo(0, 0);
            navigate("/Signin");
          }
        } else {
          window.scrollTo(0, 0);
          navigate("/Signin");
          setLodingmodalopen(false);
        }
      });
    };
    f();
  }, [upload]);

  async function loadVoteHistory() {
    let querySnapshot = await getUserPrivateVoteDescStartAfter(
      targetDateVote,
      100
    );
    let votelist = votedata;

    querySnapshot.forEach((doc) => {
      votelist[doc.id] = {};
      votelist[doc.id]["claim"] = doc.data().claim;
      votelist[doc.id]["isAgree"] = doc.data().isAgree;
      votelist[doc.id]["votedAt"] = parseAsMoment(doc.data().votedAt.toDate())
        .format("YYYY/MM/DD HH:mm")
        .toString();
      votelist[doc.id]["votedClaim"] = doc.data().votedClaim;
    });
    if (querySnapshot.size) {
      setTargetDateVote(
        Timestamp.fromDate(querySnapshot.docs.pop().data().votedAt.toDate())
      );
      setVoteData(votelist);
    }
  }

  return (
    <>
      <div
        style={{
          backgroundColor: props.bgColor.back01,
          color: props.bgColor.text01.fontColor,
        }}
      >
        <div className={classes.space4}></div>

        <TableContainer
          component={Paper}
          style={{ maxWidth: "800px", margin: "0 auto" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  質問
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  回答
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(votedata).map(([key, vote]) => (
                <TableRow key={key}>
                  <TableCell style={{ textAlign: "center", padding: "12px" }}>
                    <Typography variant="body1" fontWeight="bold">
                      {vote.claim}？
                    </Typography>
                  </TableCell>
                  <TableCell style={{ textAlign: "center", padding: "12px" }}>
                    <Typography variant="body1">
                      {vote.isAgree
                        ? "その意見に賛成です．"
                        : "その意見に反対です．"}{" "}
                      {vote.votedClaim}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <LodingModal open={lodingmodalopen} setOpen={setLodingmodalopen} />

      <Footer
        bgColor={props.bgColor}
        styletext={props.styletext}
        isimgshow={true}
      />
    </>
  );
};
export default Userpage;
