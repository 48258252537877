import { useEffect, useState } from "react";

import { Datas } from "../../common/types";

export const createGen = (message: string, maxLength = 40) => {
  const lines = message.split("\n").map((line) => line.trim());
  const result = [];
  let index = 1;

  // 名前 → chara 変換マップ
  const charaMap: { [key: string]: string } = {
    山内: "yama",
    長田: "osa",
  };

  // 感情 → pose 数値変換マップ
  const poseMap: { [key: string]: number } = {
    棒立ち_笑顔: 1,
    棒立ち_普通: 2,
    棒立ち_呆れ: 3,
    指さし_笑顔: 4,
    指さし_普通: 5,
    指さし_呆れ: 6,
  };

  let currentChara = "";
  let currentPose = 0;
  let currentPose2 = 0;

  for (const line of lines) {
    // 【話し手：山内 - 棒立ち_普通 / 聞き手：長田 - 棒立ち_普通】 のパターンを探す
    const match = line.match(
      /【話し手：(.+?) - (.+?) \/ 聞き手：(.+?) - (.+?)】/
    );

    if (match) {
      const speaker = match[1]; // 話し手
      const speakerPose = match[2].replace("・", "_"); // 話し手のポーズ
      const listenerPose = match[4].replace("・", "_"); // 聞き手のポーズ

      if (charaMap[speaker] && poseMap[speakerPose]) {
        currentChara = charaMap[speaker];
        currentPose = poseMap[speakerPose];
      }
      if (poseMap[listenerPose]) {
        currentPose2 = poseMap[listenerPose];
      }
    } else if (currentChara && currentPose && currentPose2) {
      // 長文を分割
      const splitText = splitLongText(line, maxLength);
      for (const part of splitText) {
        result.push({
          index: index++,
          chara: currentChara,
          pose: currentPose,
          pose2: currentPose2, // 聞き手のポーズのみ格納
          text: part,
        });
      }
    }
  }
  return result;
};

const splitLongText = (text: string, maxLength: number): string[] => {
  const parts: string[] = [];
  let start = 0;

  while (start < text.length) {
    let end = start + maxLength;

    if (end < text.length) {
      // できるだけ句読点 `、。` や `？` や `...` や `！` の後で切る
      let lastBreak = Math.max(
        text.lastIndexOf("、", end),
        text.lastIndexOf("。", end),
        text.lastIndexOf("？", end),
        text.lastIndexOf("！", end),
        text.lastIndexOf("...", end)
      );

      // 助詞や接続詞が次の行に行かないようにする
      const avoidBreakBefore = /[はがをにでともよねさ]/;
      while (lastBreak > start && avoidBreakBefore.test(text[lastBreak + 1])) {
        lastBreak--;
      }

      if (lastBreak > start) {
        end = lastBreak + 1; // 該当文字の次で切る
      } else {
        // 適当なスペースで分割（単語の途中で切れにくくする）
        const lastSpace = text.lastIndexOf(" ", end);
        if (lastSpace > start) {
          end = lastSpace + 1;
        } else {
          // 助詞や接続詞の前で切る
          for (let i = end; i > start; i--) {
            if (avoidBreakBefore.test(text[i])) {
              end = i;
              break;
            }
          }
        }
      }
    }

    parts.push(text.slice(start, end).trim());
    start = end;
  }

  return parts;
};
