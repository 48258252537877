import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import {
  Button,
  Grid,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  Checkbox,
  IconButton,
  ListItem,
  ListItemText,
} from "@mui/material";
import { getAuth, updateProfile } from "firebase/auth";
import React, { useState } from "react";

type Props = {
  bgColor: any;
  genko: any;
  setGenko: any;
  remakeCountIncriment: any;
  setUploadChangeGenkoFlag: any;
};

const GenkoChanger: React.VFC<Props> = ({
  bgColor,
  genko,
  setGenko,
  remakeCountIncriment,
  setUploadChangeGenkoFlag,
}: Props) => {
  const changeGenkoChara = () => {
    const nextgenko = genko;

    nextgenko.map((item: any, index: any) => {
      if (item.chara == "youmu") {
        // console.log(index + " : 霊夢" + item.chara);
        nextgenko[index].chara = "yama";
      } else if (item.chara == "reimu") {
        // console.log(index + " : 魔理沙" + item.chara);
        nextgenko[index].chara = "osa";
      }
    });
    console.log(nextgenko);

    setGenko(nextgenko);
    remakeCountIncriment(0, "", "", "remake");
    setUploadChangeGenkoFlag(true);
  };

  return (
    <>
      <br />
      <Button
        style={{
          color: bgColor.text01.fontColor,
          backgroundColor: bgColor.back06,
        }}
        onClick={() => {
          changeGenkoChara();
        }}
      >
        危険：変換ボタン(youmu→yama / reimu→osa)
      </Button>
    </>
  );
};

export default GenkoChanger;
