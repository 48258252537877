import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Avatar } from "@mui/material";
import { useStyles } from "../../../../Styles";
import { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
//import TextSegmenter from "../../../common/TextSegmenter";

import t02_01 from "../../../../images/createpic/type2/t02_01.jpg";
import t02_02 from "../../../../images/createpic/type2/t02_02.jpg";
import t02_03 from "../../../../images/createpic/type2/t02_03.jpg";

import t03_01 from "../../../../images/createpic/type3/t03_01.jpg";
import t03_02 from "../../../../images/createpic/type3/t03_02.jpg";
import t03_03 from "../../../../images/createpic/type3/t03_03.jpg";

import LonpaPictureType100 from "./PictureType/LonpaPictureType100";
import LonpaPictureType101 from "./PictureType/LonpaPictureType101";
import LonpaPictureType102 from "./PictureType/LonpaPictureType102";
import LonpaPictureType99 from "./PictureType/LonpaPictureType99";
import LonpaPictureType1 from "./PictureType/LonpaPictureType1";

const width = 512;
const height = 512;
var x = 20; // 水平位置
var y = 25; // 垂直位置
var lineHeight = 1.1618; // 行の高さ (フォントサイズに対する倍率)

export default function LonpaPictures(props) {
  const classes = useStyles();
  const [png, setPng] = useState(null);
  const [png2, setPng2] = useState(null);
  const [png3, setPng3] = useState(null);
  const [png4, setPng4] = useState(null);
  const [fontSize, setFontSize] = useState(50);
  const [isMaking, setIsMaking] = useState(true);

  const makeline = (ctx) => {
    const marginSpace = 30;
    ctx.clearRect(0, 0, width, height);
    ctx.fillStyle = "#dddddd";
    ctx.fillRect(0, 0, width, height);
    ctx.strokeStyle = "#bbbbbb";
    // ctx.beginPath();
    // ctx.setLineDash([10, 4]);
    // ctx.moveTo(marginSpace, marginSpace);
    // ctx.lineTo(width - marginSpace, marginSpace);
    // ctx.lineTo(width - marginSpace, height - marginSpace);
    // ctx.lineTo(marginSpace, height - marginSpace);
    // ctx.closePath();
    ctx.stroke();
  };

  const drawBorder = (ctx) => {
    const borderWidth = 40; // 太いふちの太さ
    const thinBorderWidth = 4; // 細い線の太さ
    ctx.globalAlpha = 1.0; // 透過を無効化

    // 太い枠（白色）
    ctx.lineWidth = borderWidth;
    ctx.strokeStyle = "#FFFFFF"; // 白色の枠
    ctx.strokeRect(0, 0, width, height);

    // 細い枠（datas.bgColor.back04 色）
    ctx.lineWidth = thinBorderWidth;
    ctx.strokeStyle = "#FFFFFF";

    // 内側に少し縮めた位置に細い枠を描く
    ctx.strokeRect(
      borderWidth / 2 - thinBorderWidth / 2,
      borderWidth / 2 - thinBorderWidth / 2,
      width - borderWidth + thinBorderWidth,
      height - borderWidth + thinBorderWidth
    );
  };

  const makeball = (ctx, alpha, color, x, y, size) => {
    ctx.globalAlpha = alpha;
    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.arc(x, y, size, 0, Math.PI * 2, true);
    ctx.fill();
  };
  const maketexts = (ctx, picturetext) => {
    const font = "bold " + (fontSize - 0) + "px Arial";
    ctx.globalAlpha = 0.9;
    ctx.font = font;

    if (picturetext) {
      // 1行ずつ描画
      for (
        var lines = picturetext.split("\n"), i = 0, l = lines.length;
        l > i;
        i++
      ) {
        var line = lines[i];
        var addY = fontSize;

        // 2行目以降の水平位置は行数と lineHeight を考慮する
        if (i) addY += fontSize * lineHeight * i;

        var textWidth = ctx.measureText(line).width;
        var textX = (width - textWidth) / 2;
        var textY = y + addY;

        // **ふちの描画**
        ctx.lineWidth = 6;
        ctx.strokeStyle = "white";
        ctx.strokeText(line, textX, textY);

        // **文字の描画**
        ctx.fillStyle = "black";
        ctx.fillText(line, textX, textY);
      }
    }
  };

  const maketext = (ctx, picturetext) => {
    var textWidth = ctx.measureText(picturetext).width;
    ctx.fillText(picturetext, (width - textWidth) / 2, height - 100);
  };

  const makeone = (picturetext) => {
    const canvasElem = document.createElement("canvas");

    canvasElem.width = width;
    canvasElem.height = height;

    const ctx = canvasElem.getContext("2d");
    if (!canvasElem || !ctx) return;

    // draw
    makeline(ctx);

    makeball(ctx, 0.56, "#768fff", 30, height / 2, height / 3);
    makeball(ctx, 0.56, "#ff5944", width - 30, height / 2, height / 3);

    maketexts(ctx, picturetext);
    // maketext(ctx, "Lonpa");
    drawBorder(ctx);

    setPng(canvasElem.toDataURL());
    return canvasElem.toDataURL();
  };

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

  const maketwo = (picturetext) => {
    const canvasElem = document.createElement("canvas");
    canvasElem.width = width;
    canvasElem.height = height;
    const ctx = canvasElem.getContext("2d");
    if (!canvasElem || !ctx) return;
    const font = "bold " + (fontSize - 0) + "px Arial";

    // draw

    makeline(ctx);

    makeball(ctx, 0.56, "#768fff", 200, height / 2, height / 2);

    maketexts(ctx, picturetext);

    setPng2(canvasElem.toDataURL());
  };

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

  const makethree = (picturetext) => {
    const canvasElem = document.createElement("canvas");
    canvasElem.width = width;
    canvasElem.height = height;
    const ctx = canvasElem.getContext("2d");
    if (!canvasElem || !ctx) return;
    const font = "bold " + (fontSize - 0) + "px Arial";

    // draw

    makeline(ctx);
    makeball(ctx, 0.56, "#ff5944", width - 200, height / 2, height / 2);

    maketexts(ctx, picturetext);

    setPng3(canvasElem.toDataURL());
  };

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

  const makefour = (picturetext) => {
    const canvasElem = document.createElement("canvas");
    canvasElem.width = width;
    canvasElem.height = height;
    const ctx = canvasElem.getContext("2d");
    if (!canvasElem || !ctx) return;
    const font = "bold " + (fontSize - 0) + "px Arial";

    // draw

    makeline(ctx);

    makeball(ctx, 0.56, "#eeeeff", 200, height / 2, height / 3);
    makeball(ctx, 0.56, "#ffeeee", width - 200, height / 2, height / 3);

    maketexts(ctx, picturetext);

    setPng4(canvasElem.toDataURL());
  };

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

  const makepicimg = (picturetext, picsrc, picnum, showlonpa) => {
    const canvasElem = document.createElement("canvas");

    canvasElem.width = width;
    canvasElem.height = height;

    const ctx = canvasElem.getContext("2d");
    if (!canvasElem || !ctx) return;

    const chara = new Image();
    chara.src = picsrc; // 画像のURLを指定

    // draw
    chara.onload = () => {
      ctx.drawImage(chara, 0, 0);
      maketexts(ctx, picturetext);
      if (showlonpa) {
        maketext(ctx, "Lonpa");
      }

      if (picnum == 1) {
        setPng(canvasElem.toDataURL());
      } else if (picnum == 2) {
        setPng2(canvasElem.toDataURL());
      } else if (picnum == 3) {
        setPng3(canvasElem.toDataURL());
      } else {
        setPng4(canvasElem.toDataURL());
      }
    };
    return canvasElem.toDataURL();
  };

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

  //コメント追加したり、他人が追加したりした場合に最新のコメントにスクロール
  useEffect(() => {
    console.log("uE_LonpaPicture");
    //console.log(props.picType);
    setIsMaking(true);

    if (props.picText && props.picText2 && props.picText3 && props.picText4) {
      if (props.picType == 1) {
        makeone(props.picText);
        maketwo(props.picText2);
        makethree(props.picText3);
        makefour(props.picText4);
      } else if (props.picType == 2) {
        makepicimg(props.picText, t02_01, 1, true);
        makepicimg(props.picText2, t02_02, 2, false);
        makepicimg(props.picText3, t02_03, 3, false);
        makepicimg(props.picText4, t02_01, 4, false);
      } else if (props.picType == 3) {
        makepicimg(props.picText, t03_01, 1, true);
        makepicimg(props.picText2, t03_02, 2, false);
        makepicimg(props.picText3, t03_03, 3, false);
        makepicimg(props.picText4, t03_01, 4, false);
      } else if (props.picType == 99) {
        //console.log("qr");
      } else {
        console.log("それは定義されていない");
      }
    } else {
      if (props.picType == 100) {
        makeone(props.picText);
      } else if (props.picType == 101) {
        //console.log(props.aiImage);
        //chatGPTImage(props.picText);
        //makeone(props.picText);
      } else {
        makeone("");
        maketwo("");
        makethree("");
        makefour("");
      }
    }

    setIsMaking(false);
  }, [props.url, props.picType, props.picText]);

  useEffect(() => {
    console.log("uE_LonpaPicture");
    if (props.autoCreateFlag) {
      props.setMoviepngAndFlag(makeone(props.picText));
    }
  }, [props.autoCreateFlag]);

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

  return (
    <>
      {isMaking ? (
        <>作成中</>
      ) : (
        <>
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            {props.picType === 100 ? (
              <LonpaPictureType100
                setMoviepngAndFlag={props.setMoviepngAndFlag}
                makeone={makeone}
                picText={props.picText}
                png={png}
                makepicimg={makepicimg}
              />
            ) : (
              <>
                {props.picType === 101 ? (
                  <>
                    <LonpaPictureType101 aiImage={props.aiImage} />
                    <Button
                      onClick={() => {
                        props.imageUploadButtonPush();
                      }}
                      align="center"
                      style={{
                        backgroundColor: "#dddddd",
                      }}
                    >
                      設定する
                    </Button>
                  </>
                ) : (
                  <>
                    {props.picType === 102 ? (
                      <LonpaPictureType102 />
                    ) : (
                      <>
                        {props.picType === 99 ? (
                          <LonpaPictureType99 url={props.url} />
                        ) : (
                          <>
                            {props.picType === 98 ? (
                              <></>
                            ) : (
                              <LonpaPictureType1
                                png={png}
                                png2={png2}
                                png3={png3}
                                png4={png4}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
}
