import { firebaseApp, userApp } from "../firebase";
import {
  getStorage,
  ref,
  uploadString,
  uploadBytesResumable,
  getDownloadURL,
  getBytes,
} from "firebase/storage";

export async function movieStringUpload(lonpaid, text, title) {
  const storage = getStorage(firebaseApp);
  //console.log(props.lonpaid);
  const storageRef = ref(storage, "movie/" + lonpaid + "/" + title); //どのフォルダの配下に入れるかを設定
  await uploadString(storageRef, text).then((snapshot) => {
    return true;
  }); //ファイル名
  return false;
}
export async function moviePictureUpload(lonpaid, png, title) {
  if (typeof png === "undefined") {
    return;
  }
  /// base64から画像データを作成
  const bin = atob(png.replace(/^.*,/, ""));
  let barr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    barr[i] = bin.charCodeAt(i);
  }
  const blob = new Blob([barr], { type: "image/png" });
  const file = new File([blob], "up.png", {
    type: "image/png",
  });

  const storage = getStorage(firebaseApp);
  const storageRef = ref(storage, "movie/" + lonpaid + "/" + title); //どのフォルダの配下に入れるかを設定

  // ファイルをアップロード
  return new Promise((resolve, reject) => {
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      null,
      (error) => {
        reject(error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        } catch (error) {
          reject(error);
        }
      }
    );
  });
}

export async function getMovieString(lonpaid, title) {
  // Create a reference to the file we want to download
  const storage = getStorage();
  const starsRef = ref(storage, "movie/" + lonpaid + "/" + title);
  let utf8String;

  try {
    await getBytes(starsRef).then((data) => {
      const uint8 = new Uint8Array(data);
      const decoder = new TextDecoder("utf-8");
      utf8String = decoder.decode(uint8);
    });
    return utf8String;
  } catch (e) {
    return false;
  }
}
