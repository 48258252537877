import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  TextField,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import SaveIcon from "@mui/icons-material/Save";
import { red } from "@mui/material/colors";

import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function MakeCsv(props) {
  const [genkoTable, setGenkoTable] = useState(<>test</>);
  const [genko, setGenko] = useState();
  // const [isEditmode, setIsEditmode] = useState(false);

  useEffect(() => {
    console.log("uE_MakeCsv");
    setGenko(props.genko);
    createGenkoTable(props.genko, -1, "");
  }, [props.genko]);

  useEffect(() => {
    console.log("uE_MakeCsv");
    if (genko) {
      if (props.remakeCount.updateindex != -1) {
        if (props.remakeCount.changetype == "change") {
          let nextgenko = genko;
          nextgenko[props.remakeCount.updateindex].text = props.changeText;
          nextgenko[props.remakeCount.updateindex].chara = props.changechara;
          nextgenko = createNextGenkoReCountnum(nextgenko);
          nextgenko.map((item, index) => {
            nextgenko[index].index = index;
          });
          setGenko(nextgenko);
          createGenkoTable(nextgenko, -1, "");
        } else if (props.remakeCount.changetype == "add") {
          console.log("add");
          let nextgenko = genko;
          nextgenko.splice(props.remakeCount.updateindex, 0, {
            index: "999",
            chara: "",
            pose: "",
            pose2: "",
            text: "",
            isShortMovie: true,
          });
          nextgenko.map((item, index) => {
            nextgenko[index].index = index;
          });
          setGenko(nextgenko);
          createGenkoTable(nextgenko, -1, "");
        } else if (props.remakeCount.changetype == "remake") {
          createGenkoTable(genko, -1, "");
        }
      }
    }
  }, [props.remakeCount]);

  const createNextGenkoReCountnum = (nextgenko) => {
    let strnum = 0;
    const shortMovieStrNum = 400;
    nextgenko.map((item, index) => {
      strnum = strnum + item.text.length + 4;
      // console.log(index);
      // console.log(nextgenko[index].text);
      // console.log(item.text);
      // console.log(item.text.length);
      nextgenko[index].isShortMovie = strnum < shortMovieStrNum;
      nextgenko[index].index = index;
    });

    props.createIsMovieShort(strnum < shortMovieStrNum);
    return nextgenko;
  };

  const theme = createTheme({
    palette: {
      lamactstyle: {
        main: props.bgColor.text01.fontColor,
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#242105",
        disabled: "#ffffff",
      },
      action: {
        disabled: props.bgColor.text01.fontColor,
      },
    },
  });

  const createGenkoTable = (
    nextgenko,
    updateindex,
    text,
    chara,
    buttonflag
  ) => {
    setGenkoTable(
      nextgenko.map((item, index) => {
        return (
          <ThemeProvider theme={theme}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    {buttonflag ? (
                      <>
                        <TableCell
                          align="right"
                          style={{ width: "5%", padding: "0px" }}
                        >
                          <IconButton
                            aria-label="delete"
                            size="small"
                            disabled={true}
                            style={{ color: props.bgColor.text01.fontColor }}
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell
                          align="right"
                          style={{ width: "5%", padding: "0px" }}
                        >
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => {
                              deleteGenkoTable(index);
                            }}
                            style={{ color: props.bgColor.text01.fontColor }}
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                      </>
                    )}

                    {item.isShortMovie ? (
                      <>
                        <TableCell
                          align="center"
                          style={{
                            width: "2%",
                            padding: "0px",
                            backgroundColor: props.bgColor.back01,
                            color: props.bgColor.text01.fontColor,
                          }}
                        >
                          {item.index}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell
                          align="center"
                          style={{
                            width: "2%",
                            padding: "0px",
                            backgroundColor: props.bgColor.back02,
                            color: props.bgColor.text01.fontColor,
                          }}
                        >
                          {item.index}
                        </TableCell>
                      </>
                    )}

                    {index == updateindex ? (
                      <>
                        <TableCell
                          align="center"
                          style={{
                            width: "28%",
                            padding: "0px",
                            backgroundColor: props.bgColor.back01,
                            color: props.bgColor.text01.fontColor,
                          }}
                        >
                          <Select
                            defaultValue={chara}
                            onChange={(event) => {
                              props.setChangeChara(event.target.value);
                            }}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            style={{ color: props.bgColor.text01.fontColor }}
                          >
                            <MenuItem value={"yama"}>yama</MenuItem>
                            <MenuItem value={"osa"}>osa</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            width: "28%",
                            padding: "0px",
                            backgroundColor: props.bgColor.back01,
                            color: props.bgColor.text01.fontColor,
                          }}
                        >
                          <Select
                            defaultValue={1}
                            onChange={(event) => {
                              props.setChangePose(event.target.value);
                            }}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            style={{ color: props.bgColor.text01.fontColor }}
                          >
                            <MenuItem value={"1"}>1</MenuItem>
                            <MenuItem value={"2"}>2</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ width: "60%", padding: "0px" }}
                        >
                          <TextField
                            id="standard-basic"
                            label=""
                            variant="standard"
                            multiline
                            maxRows={20}
                            minRows={1}
                            defaultValue={text}
                            onChange={(event) => {
                              props.setChangeText(event.target.value);
                            }}
                            sx={{
                              input: {
                                color: props.bgColor.text04.fontColor,
                              },
                            }}
                            style={{
                              width: "100%",
                              border: props.bgColor.line02,
                              color: props.bgColor.text01.fontColor,
                            }}
                            color="lamactstyle"
                          />
                        </TableCell>

                        <TableCell
                          align="right"
                          style={{ width: "10%", padding: "0px" }}
                        >
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => {
                              //setChangeIndex(-1);
                              changeGenkoTable(index, item.text);
                              // setIsEditmode(false);
                            }}
                            style={{ color: props.bgColor.text01.fontColor }}
                          >
                            <SaveIcon fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        {/* <TableCell
                          align="center"
                          style={{
                            width: "28%",
                            padding: "0px",
                            backgroundColor: props.bgColor.back01,
                            color: props.bgColor.text01.fontColor,
                          }}
                        >
                          {item.chara}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            width: "28%",
                            padding: "0px",
                            backgroundColor: datas.bgColor.back01,
                            color: datas.bgColor.text01.fontColor,
                          }}
                        >
                          {item.pose}
                        </TableCell> */}

                        <TableCell
                          align="center"
                          style={{
                            width: "28%",
                            padding: "0px",
                            backgroundColor: props.bgColor.back01,
                            color: props.bgColor.text01.fontColor,
                          }}
                        >
                          {
                            item.chara //★偽りの表示
                          }
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            width: "28%",
                            padding: "0px",
                            backgroundColor: props.bgColor.back01,
                            color: props.bgColor.text01.fontColor,
                          }}
                        >
                          {item.pose}
                          {
                            //★偽りの表示
                          }
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            width: "28%",
                            padding: "0px",
                            backgroundColor: props.bgColor.back01,
                            color: props.bgColor.text01.fontColor,
                          }}
                        >
                          {item.pose2}
                          {
                            //★偽りの表示
                          }
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            width: "60%",
                            padding: "0px",
                            color: props.bgColor.text01.fontColor,
                          }}
                        >
                          {item.text}
                        </TableCell>

                        {buttonflag ? (
                          <>
                            <TableCell
                              align="right"
                              style={{ width: "5%", padding: "0px" }}
                            >
                              <IconButton
                                aria-label="delete"
                                size="small"
                                disabled={true}
                                style={{
                                  color: props.bgColor.text01.fontColor,
                                }}
                              >
                                <ChangeCircleIcon fontSize="inherit" />
                              </IconButton>
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell
                              align="right"
                              style={{ width: "5%", padding: "0px" }}
                            >
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => {
                                  selectGenkoTable(
                                    index,
                                    item.text,
                                    item.chara,
                                    item.pose,
                                    item.pose2
                                  );
                                }}
                                style={{
                                  color: props.bgColor.text01.fontColor,
                                }}
                              >
                                <ChangeCircleIcon fontSize="inherit" />
                              </IconButton>
                            </TableCell>
                          </>
                        )}
                      </>
                    )}

                    {buttonflag ? (
                      <>
                        <TableCell
                          align="right"
                          style={{ width: "5%", padding: "0px" }}
                        >
                          <IconButton
                            aria-label="delete"
                            size="small"
                            disabled={true}
                            style={{ color: props.bgColor.text01.fontColor }}
                          >
                            <AddCircleOutlineIcon fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell
                          align="right"
                          style={{ width: "5%", padding: "0px" }}
                        >
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => {
                              //console.log("追加");
                              // setIsEditmode(true);
                              addGenkoTable(index);
                            }}
                            style={{ color: props.bgColor.text01.fontColor }}
                          >
                            <AddCircleOutlineIcon fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </ThemeProvider>
        );
      })
    );
  };

  const deleteGenkoTable = (index) => {
    let nextgenko = props.genko;
    nextgenko.splice(index, 1);
    props.setChangeText("");
    nextgenko = createNextGenkoReCountnum(nextgenko);
    createGenkoTable(nextgenko, -1, "", "", false);
    props.setUploadChangeGenkoFlag(true);
  };

  const selectGenkoTable = (updateindex, text, chara, pose, pose2) => {
    props.setChangeText(text);
    props.setChangeChara(chara);
    props.setChangePose(pose);
    props.setChangePose2(pose2);
    createGenkoTable(props.genko, updateindex, text, chara, pose, pose2, true);
  };

  const changeGenkoTable = (updateindex, text, chara, pose, pose2) => {
    props.remakeCountIncriment(updateindex, text, chara, pose, pose2, "change");
    props.setUploadChangeGenkoFlag(true);
  };

  const addGenkoTable = (updateindex) => {
    props.remakeCountIncriment(updateindex, "", "reimu", "add");
    props.setUploadChangeGenkoFlag(true);
  };

  return <div>{genkoTable}</div>;
}
