import { useStyles } from "../Styles";
import { AES, enc } from "crypto-js";
import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";

import {
  createPickupTransaction,
  getPickupTransaction,
} from "../common/Helper/FirebaseTransactionHelper";

import {
  movieStringUpload,
  moviePictureUpload,
  getMovieString,
} from "../common/Helper/StorageHelper";

import {
  Button,
  Grid,
  TextField,
  Container,
  Checkbox,
  FormGroup,
  FormControlLabel,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  RadioGroup,
  Radio,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import LodingModal from "./CommonModal/LodingModal";
import LonpaPictures from "./Lonpa/Modal/Picture/LonpaPictures";
import MakeCsv from "./MovieMaker/MakeCsv";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MovieUsePointModal from "./MovieMaker/Modal/MovieUsePointModal";

import {
  getAllLonpaChild,
  getLonpaList,
  getLonpa,
} from "../common/Helper/FirebaseHelper";

import URLList from "./MovieMaker/Data/link.json";

export default function MovieMakerold(props) {
  let { lonpaid } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const [togglenum, setToggleNum] = useState([3]);
  const [checked, setChecked] = useState(true);

  const [hira, setHira] = useState("");
  const [annkey, setAnnkey] = useState("");
  const [outdata, setOutdata] = useState("");

  const [nameAgree, setNameAgree] = useState("【女子A - 賛成】");
  const [nameDisagree, setNameDisagree] = useState("【女子B - 反対】");
  const [oneTextShow, setOneTextShow] = useState("");
  const [twoTextShow, setTwoTextShow] = useState();
  const [threeTextShow, setThreeTextShow] =
    useState("まだその機能は実装されていないよ");
  const [fourTextShow, setFourTextShow] = useState("");
  const [fiveTextShow, setFiveTextShow] = useState(
    "【女子A - 賛成】\nてすとA\n【女子B - 反対】\nてすとB"
  );
  const [lodingmodalopen, setLodingmodalopen] = useState(false);

  const [genko, setGenko] = useState([]);
  const [isopenone, setIsopenone] = useState(false);
  const [isopentwo, setIsopentwo] = useState(true);
  const [isopenthree, setIsopenthree] = useState(false);
  const [isShort, setIsShort] = useState(true);

  const [randId, setRandId] = useState(0);
  const [isflagone, setIsflagone] = useState(false);
  const [isflagtwo, setIsflagtwo] = useState(false);
  const [isflagthree, setIsflagthree] = useState(false);
  const [isflagfour, setIsflagfour] = useState(false);
  const [isflagfive, setIsflagfive] = useState(false);

  const [genkocsv, setGenkocsv] = useState("未保存");

  const [explainvideoInput, setExplainvideoInput] = useState("未保存");
  const [explainvideo, setExplainvideo] = useState("未保存");

  const [moviepng, setMoviepng] = useState("未保存");

  const [autoCreateFlag, setAutoCreateFlag] = useState(false);

  //特殊実装用
  const [changeText, setChangeText] = useState();
  const [changeVocal, setChangeVocal] = useState();
  const [remakeCount, setRemakeCount] = useState({
    num: 0,
    updateindex: "",
    text: "",
  });

  //remotionテキスト
  const [remotionTextShow, setRemotionTextShow] = useState("");
  const [expanded, setExpanded] = useState(false);

  const [movieStage, setMovieStage] = useState({
    1: "完",
    2: "未",
    3: "未",
    4: "未",
    5: "未",
    6: "未",
    7: "未",
    8: "未",
    9: "未",
  });

  const [saveStatus, setSaveStatus] = useState({
    1: "未",
    2: "未",
    3: "未",
    4: "未",
    5: "未",
  });

  const [movieSetting, setMovieSetting] = useState({
    set1: "1",
    set2: "1",
    set3: "1",
    set4: "0",
    set5: "0",
  });
  const [movieSettingText, setMovieSettingText] = useState(
    "movie,set1,set2,set3,set4,set5\nx,1,1,1,0,0"
  );
  const [movieUsePointmodalopen, setMovieUsePointmodalopen] = useState(false);
  const [pointPattarn, setPointPattarn] = useState("");
  const [editDatas, setEditDatas] = useState([]);
  const [isInitFinish, setIsInitFinish] = useState(false);

  const urlList = URLList;

  const executeAutomake = async () => {
    props.setAutoCreateMovie({
      create: true,
      id: editDatas.id,
      rapInputCreate: true,
      rapCreate: true,
      genCreate: true,
      csvCreate: true,
      imageCreate: true,
      settingCreate: true,
      explainCreate: true,
      saveCreate: true,
    });
  };

  const executeMakeManuscript = async () => {
    createRap(fourTextShow);
  };

  const executeMakeSNS = async () => {
    createExplainvideo(explainvideoInput);
  };

  const executeMakeMovieOnly = async () => {
    setIsflagfour(true);
    createMovie();
  };

  //自動作成autoCreateMovie
  const autoCreate = async (nextEditDatas) => {
    let useEditDatas;
    if (nextEditDatas) {
      useEditDatas = nextEditDatas;
    } else {
      useEditDatas = editDatas;
    }
    //console.log(useEditDatas);

    if (props.autoCreateMovie.create) {
      if (props.autoCreateMovie.rapInputCreate) {
        setLodingmodalopen(true);
        setIsopenone(true);
        setIsopentwo(false);
        setIsopenthree(false);
        setExpanded(expanded === "panel2" ? false : "panel2");
        createRapInput(useEditDatas);
      } else if (props.autoCreateMovie.rapCreate) {
        setExpanded(expanded === "panel3" ? false : "panel3");
        createRap(fourTextShow);
      } else if (props.autoCreateMovie.genCreate) {
        setExpanded(expanded === "panel4" ? false : "panel4");
        createGen2(fiveTextShow);
      } else if (props.autoCreateMovie.csvCreate) {
        setExpanded(expanded === "panel5" ? false : "panel5");
        createCsv(genko);
      } else if (props.autoCreateMovie.settingCreate) {
        setExpanded(expanded === "panel6" ? false : "panel6");
        setMovieSetting({
          set1: "1",
          set2: "1",
          set3: "1",
          set4: "0",
          set5: "0",
        });
        createMovieSettingText({
          set1: "1",
          set2: "1",
          set3: "1",
          set4: "0",
          set5: "0",
        });
        setMovieStage({ ...movieStage, 6: "完" });
      } else if (props.autoCreateMovie.imageCreate) {
        setExpanded(expanded === "panel7" ? false : "panel7");
        setAutoCreateFlag(true);
      } else if (props.autoCreateMovie.explainCreate) {
        setExpanded(expanded === "panel9" ? false : "panel9");
        createExplainvideo(explainvideoInput);
      } else if (props.autoCreateMovie.saveCreate) {
        setExpanded(expanded === "panel10" ? false : "panel10");

        if (
          movieStage[5] == "完" &&
          movieStage[6] == "完" &&
          movieStage[7] == "完"
        ) {
          uploadDatas();
        }
      } else {
        setLodingmodalopen(false);
        props.setAutoCreateMovie({ ...props.autoCreateMovie, create: false });
      }
    }
  };

  //クリップボードにコピー関数
  const copyToClipboard = async (copyText) => {
    await global.navigator.clipboard.writeText(copyText);
  };

  useEffect(() => {
    console.log("uE_MovieMaker");
    const f = async () => {
      //ログイン状態だった場合，自身が過去投票したlonpaの一覧を取得する
      const doc = await getPickupTransaction(lonpaid);
      //ログイン中のアカウントで，選択中のlonpa一覧
      if (doc) {
        if (doc.data()) {
          setRandId(doc.data().id);
          setIsflagone(doc.data().isflagone);
          setIsflagtwo(doc.data().isflagtwo);
          setIsflagthree(doc.data().isflagthree);
          setIsflagfour(doc.data().isflagfour);
          setIsflagfive(doc.data().isflagfive);
        }
      }

      let nextEditDatas;
      try {
        nextEditDatas = await getLonpaInfo(lonpaid);
        setEditDatas(nextEditDatas);
      } catch {
        console.log("lonpaデータが取れませんでした");
      } finally {
        createExplainvideoInput(nextEditDatas);
        setMovieSettingText(
          "movie,set1,set2,set3,set4,set5\n" + lonpaid + ",1,1,1,0,0"
        );
        setMovieStage({ ...movieStage, 6: "完", 8: "完" });
        createRapInput(nextEditDatas);
        //console.log(nextEditDatas);
        setIsInitFinish(true);
        autoCreate(nextEditDatas);
      }
    };
    f();
  }, []);

  useEffect(() => {
    console.log("uE_MovieMaker_autoCreateMovie");
    console.log("isInitFinish" + isInitFinish);
    const f = async () => {
      if (isInitFinish) {
        if (props.autoCreateMovie) {
          if (props.autoCreateMovie.create) {
            //console.log("自動作成アリ");
            autoCreate(false);
          } else {
            //console.log("自動作成ナシ");
          }
        }
      }
    };
    f();
  }, [props.autoCreateMovie]);

  const setMoviepngAndFlag = (png) => {
    setMoviepng(png);
    setMovieStage({ ...movieStage, 7: "完" });

    //自動生成フラグ
    if (props.autoCreateMovie.imageCreate) {
      props.setAutoCreateMovie({
        ...props.autoCreateMovie,
        imageCreate: false,
      });
      //useEffectが動く
    }
    setAutoCreateFlag(false);

    //console.log("画像保存済み");
  };

  const getLonpaInfo = async (lonpaid) => {
    const lonpadoc = await getLonpa(lonpaid);

    const lonpaChildSnapshot = await getAllLonpaChild(lonpaid);
    let childlonsAgree = [];
    let childlonsDisagree = [];
    for (let i = 0; i < lonpaChildSnapshot.size; i++) {
      const onedata = lonpaChildSnapshot.docs[i].data();
      const oneeditdata = {
        data: {
          agree: onedata.agree,
          claim: onedata.claim,
          isDelete: onedata.isDelete,
          votenum: onedata.votenum,
        },
      };
      if (onedata.agree) {
        childlonsAgree.push(oneeditdata);
      } else {
        childlonsDisagree.push(oneeditdata);
      }
    }
    const makeEditDatas = {
      claim: lonpadoc.data().claim,
      id: lonpaid,
      infoagree: childlonsAgree,
      infodisagree: childlonsDisagree,
      infochoice: null,
      isDelete: lonpadoc.data().isDelete,
      isopinion: lonpadoc.data().isopinion,
      parent: undefined,
    };
    return makeEditDatas;
  };

  const downloadGen = async () => {
    const gendata = await getMovieString(editDatas.id, "genko.csv");
    let stringGenData = gendata.toString();
    const trimstr = stringGenData.split("\n"); // 整形

    let allgen = [];
    let strnum = 0;
    const shortMovieStrNum = 400;

    trimstr.map((item, index) => {
      if (index > 0) {
        const trimtrimstr = item.split(","); // 整形
        if (trimtrimstr[2]) {
          strnum = strnum + trimtrimstr[2].length + 4;
          allgen.push({
            index: trimtrimstr[0],
            vocal: trimtrimstr[1],
            text: trimtrimstr[2],
            isShortMovie: strnum < shortMovieStrNum,
          });
        }
      }
    });
    setGenko(allgen);

    createIsMovieShort(strnum < shortMovieStrNum);
    setMovieStage({ ...movieStage, 4: "完" });
  };

  const remakeCountIncriment = (updateindex, text, vocal, changetype) => {
    setRemakeCount({
      num: remakeCount.num + 1,
      updateindex: updateindex,
      text: text,
      vocal: vocal,
      changetype: changetype,
    });
  };

  const handleChange = (event) => {
    //checkbox 押下の挙動

    if (!event.target.checked) {
      setIsopenone(false);
      setIsopentwo(false);
      setIsopenthree(false);
      setToggleNum([]);
      setChecked(event.target.checked);
    } else {
      if (editDatas) {
        alert(
          "\nデータがありません\nいづれかのLonpaの画面から遷移してください"
        );
        props.stopAuto();
        navigate("/Top/");
      }
    }
  };

  const handleFormat = (event, newFormats) => {
    //togglebutton　押下の挙動
    if (newFormats !== null) {
      let togglelist = [];

      setIsopenone(false);
      setIsopentwo(false);
      setIsopenthree(false);

      if (newFormats === 0) {
        if (isopenone) {
          setIsopenone(false);
        } else {
          setIsopenone(true);
          togglelist.push(0);
        }
      } else if (newFormats === 1) {
        if (isopentwo) {
          setIsopentwo(false);
        } else {
          setIsopentwo(true);
          togglelist.push(1);
        }
      } else if (newFormats === 2) {
        if (isopenthree) {
          setIsopenthree(false);
        } else {
          setIsopenthree(true);
          togglelist.push(2);
        }
      } else {
        console.log("error");
      }
      setToggleNum(togglelist);
    }
  };

  const createIsMovieShort = (flag) => {
    setIsShort(flag);
    const valuestring = flag ? "1" : "2";
    setMovieSetting({
      ...movieSetting,
      set4: valuestring,
    });
    //ここを動かすと暴発します．絶対に動かしてはいけない，理由は分からない．
    // createMovieSettingText({
    //   ...movieSetting,
    //   set4: valuestring,
    // });
  };

  const createRapInput = async (nextEditDatas) => {
    let useEditDatas;
    if (nextEditDatas) {
      useEditDatas = nextEditDatas;
    } else {
      useEditDatas = editDatas;
    }
    //console.log(useEditDatas);
    try {
      let chatGPTMessage = "";
      chatGPTMessage = chatGPTMessage + "「" + useEditDatas.claim + "」";
      chatGPTMessage =
        chatGPTMessage +
        "という意見について，\n女子2人(【女子A - 賛成】・【女子B - 反対】)によるラップバトルを書いてください\n\n";
      chatGPTMessage = chatGPTMessage + "「" + useEditDatas.claim + "」";
      chatGPTMessage = chatGPTMessage + "に対する賛成意見は\n";

      useEditDatas.infoagree.map((item) => {
        chatGPTMessage = chatGPTMessage + "「" + item.data.claim + "」\n";
      });
      chatGPTMessage = chatGPTMessage + "等がある．\n\n";
      chatGPTMessage = chatGPTMessage + "「" + useEditDatas.claim + "」";
      chatGPTMessage = chatGPTMessage + "に対する反対意見は\n";

      useEditDatas.infodisagree.map((item) => {
        chatGPTMessage = chatGPTMessage + "「" + item.data.claim + "」\n";
      });

      chatGPTMessage = chatGPTMessage + "等がある．\n\n";
      chatGPTMessage =
        chatGPTMessage +
        "ただし，下記のような設定のキャラクターになりきって文章を作成してください．\n・人間の女の子\n・大学生\n・【女子A - 賛成】\n・【女子B - 反対】\n・それぞれ3回交互に発言する\n・1回の発言は3行\n\n";

      chatGPTMessage =
        chatGPTMessage + "（発言内容）にかぎかっこは記載しないでください\n\n";

      chatGPTMessage =
        chatGPTMessage +
        "ただし、フォーマットは下記としてください\n【女子A - 賛成】\n（発言内容）\n【女子B - 反対】\n（発言内容）";
      setFourTextShow(chatGPTMessage);
      setMovieStage({ ...movieStage, 2: "完" });

      //自動生成フラグ
      if (props.autoCreateMovie.rapInputCreate) {
        props.setAutoCreateMovie({
          ...props.autoCreateMovie,
          rapInputCreate: false,
        });
        //useEffectが動く
      }

      return chatGPTMessage;
    } catch (error) {
      alert(error + "\nエラー#0006、二度と押さないでください");
      props.stopAuto();
      return false;
    }
  };

  const createRap = async (chatGPTMessage) => {
    setFiveTextShow("作成中");
    try {
      const functions = getFunctions();
      const messageCreate = httpsCallable(functions, "messageCreate");
      messageCreate({ message: chatGPTMessage }).then((result) => {
        if (result.data) {
          setFiveTextShow(result.data.aimessage);
          setMovieStage({ ...movieStage, 3: "完" });

          //自動生成フラグ
          if (props.autoCreateMovie.rapCreate) {
            props.setAutoCreateMovie({
              ...props.autoCreateMovie,
              rapCreate: false,
            });
            //useEffectが動く
          }

          return result.data.aimessage;
        } else {
          setFiveTextShow("エラー");
          return false;
        }
      });
    } catch (error) {
      alert(error + "\nエラー#0001、二度と押さないでください");
      props.stopAuto();
      setFiveTextShow("エラー");
      return false;
    }
  };

  const createExplainvideoInput = async (nextEditDatas) => {
    let useEditDatas;

    if (nextEditDatas) {
      useEditDatas = nextEditDatas;
    } else {
      useEditDatas = editDatas;
    }
    //console.log(useEditDatas);
    try {
      let chatGPTMessage = "";
      chatGPTMessage = chatGPTMessage + useEditDatas.claim;
      chatGPTMessage = chatGPTMessage + "に対する賛成意見は";

      useEditDatas.infoagree.map((item) => {
        chatGPTMessage = chatGPTMessage + "、「" + item.data.claim + "」";
      });
      chatGPTMessage = chatGPTMessage + "等がある．";

      chatGPTMessage = chatGPTMessage + useEditDatas.claim;
      chatGPTMessage = chatGPTMessage + "に対する反対意見は";

      useEditDatas.infodisagree.map((item) => {
        chatGPTMessage = chatGPTMessage + "、「" + item.data.claim + "」";
      });
      chatGPTMessage = chatGPTMessage + "等がある．";
      chatGPTMessage =
        chatGPTMessage +
        "\n\nこれらを踏まえてかわいい女子大生みたいなツイートを100文字以内で作ってください．＃は記載しないでください．";
      chatGPTMessage =
        chatGPTMessage +
        "ただし，下記のような設定のキャラクターになりきって文章を作成してください．";
      chatGPTMessage = chatGPTMessage + "\n・人間の女の子";
      chatGPTMessage = chatGPTMessage + "\n・大学生";
      chatGPTMessage = chatGPTMessage + "\n・#は使わない";
      chatGPTMessage = chatGPTMessage + "\n・タグはつけない";
      chatGPTMessage = chatGPTMessage + "\n・ツイートは100文字";

      setMovieStage({ ...movieStage, 8: "完" });
      setExplainvideoInput(chatGPTMessage);

      return chatGPTMessage;
    } catch (error) {
      alert(error + "\nエラー#0002、二度と押さないでください");
      props.stopAuto();
      return false;
    }
  };

  const createExplainvideo = async (chatGPTMessage) => {
    // setFiveTextShow("作成中");
    setExplainvideo("作成中");
    try {
      const functions = getFunctions();
      const messageCreate = httpsCallable(functions, "messageCreate");
      messageCreate({ message: chatGPTMessage }).then((result) => {
        if (result.data) {
          // setFiveTextShow(result.data.aimessage);
          setMovieStage({ ...movieStage, 9: "完" });
          setExplainvideo(createExplain(result.data.aimessage));

          //自動生成フラグ
          if (props.autoCreateMovie.explainCreate) {
            props.setAutoCreateMovie({
              ...props.autoCreateMovie,
              explainCreate: false,
            });
            //useEffectが動く
          }
          return result.data.aimessage;
        } else {
          return false;
        }
      });
    } catch (error) {
      alert(error + "\nエラー#0003、二度と押さないでください");
      props.stopAuto();
      return false;
    }
  };

  const createGen2 = async (message) => {
    try {
      let trimmessags = [];
      let agreeindexes = [];
      let disagreeindexes = [];
      let index = 0;
      let agreeindex = 0;
      let disagreeindex = 0;
      const debug = false;

      //A 1 B 1 A 2 B 2 A 3 B 3

      //■■■■■■■■■■■■■　文章整形　■■■■■■■■■■■■■■■
      //改行を「、」でつなぎ，「。」を削除する
      trimmessags.push(
        message.split("\n").join("、").split("。").join("").trim()
      ); // 整形

      //■■■■■■■■■■■■■　分割位置捜索と，先頭が区切れている配列を作る　■■■■■■■■■■■■■■■
      if (trimmessags[index].indexOf(nameDisagree)) {
        //■■■■■■■■■■■■■
        if (debug)
          console.log("agreeindex", trimmessags[index].indexOf(nameAgree));
        agreeindexes.push(trimmessags[index].indexOf(nameAgree)); // 何文字目か検索

        //■■■■■■■■■■■■■
        if (debug)
          console.log(
            "disagreeindex",
            trimmessags[index].indexOf(nameDisagree)
          );
        disagreeindexes.push(trimmessags[index].indexOf(nameDisagree)); // 何文字目か検索
      } else {
        throw new Error("意見が抽出できませんでした");
      }

      index = index + 1;
      agreeindex = agreeindex + 1;
      disagreeindex = disagreeindex + 1;

      for (let i = 0; i < 10; i++) {
        //■■■■■■■■■■■■■
        trimmessags.push(
          trimmessags[index - 1]
            .slice(disagreeindexes[disagreeindex - 1])
            .trim()
        );
        //console.log(trimmessags[index]);

        if (debug) console.log(trimmessags[index].length);
        if (trimmessags[index].length <= 1) {
          break;
        }

        if (trimmessags[index].indexOf(nameAgree)) {
          if (debug)
            console.log("agreeindex", trimmessags[index].indexOf(nameAgree));
          agreeindexes.push(trimmessags[index].indexOf(nameAgree)); // 何文字目か検索
        } else {
          throw new Error("意見が抽出できませんでした");
        }
        index = index + 1;
        agreeindex = agreeindex + 1;

        //■■■■■■■■■■■■■
        trimmessags.push(
          trimmessags[index - 1].slice(agreeindexes[agreeindex - 1]).trim()
        );
        //console.log(trimmessags[index]);

        if (debug) console.log(trimmessags[index].length);
        if (trimmessags[index].length <= 1) {
          break;
        }
        if (trimmessags[index].indexOf(nameDisagree)) {
          if (debug)
            console.log(
              "disagreeindex",
              trimmessags[index].indexOf(nameDisagree)
            );
          disagreeindexes.push(trimmessags[index].indexOf(nameDisagree)); // 何文字目か検索
        } else {
          throw new Error("意見が抽出できませんでした");
        }
        index = index + 1;
        disagreeindex = disagreeindex + 1;
      }
      if (debug) console.log("agree", agreeindexes);
      if (debug) console.log("dis", disagreeindexes);
      if (debug)
        console.log(
          "-★------------------------------------------------------------"
        );

      //■■■■■■■■■■■■■　文章分割（賛成・反対レベルで末尾も切れているようにする）　■■■■■■■■■■■■■■■
      let agreelons = [];
      let disagreelons = [];

      //■■■■■■■■■■■■■
      if (debug)
        console.log(
          trimmessags[0].slice(agreeindexes[0], disagreeindexes[0]).trim()
        );
      agreelons.push(
        trimmessags[0].slice(agreeindexes[0], disagreeindexes[0]).trim()
      ); // 賛成部分１を分割

      for (let i = 1; i < 10; i++) {
        //■■■■■■■■■■■■■
        const trimnum = i * 2 - 1;
        if (debug) console.log("trimnum:", trimnum, "   i:", i);
        try {
          if (agreeindexes[i] == -1) {
            //console.log("スライスできなかったかな");
            break;
          } else {
            if (debug)
              console.log(
                trimmessags[trimnum].slice(0, agreeindexes[i]).trim()
              );
            disagreelons.push(
              trimmessags[trimnum].slice(0, agreeindexes[i]).trim()
            ); // 賛成部分２を分割
          }
        } catch {
          //console.log("スライスできなかったかな");
          break;
        }

        if (debug) console.log("trimnum:", trimnum + 1, "   i:", i);

        //■■■■■■■■■■■■■
        try {
          if (disagreeindexes[i] == -1) {
            //console.log("スライスできなかったかな");
            break;
          } else {
            if (debug)
              console.log(
                trimmessags[trimnum + 1].slice(0, disagreeindexes[i]).trim()
              );
            agreelons.push(
              trimmessags[trimnum + 1].slice(0, disagreeindexes[i]).trim()
            ); // 反対部分１を分割
          }
        } catch {
          //console.log("スライスできなかったかな");
          break;
        }
      }
      //disagreelons.push(trimmessagefive.slice(disagreeindexthree).trim());

      let allgen = [];
      let allindex = 0;
      let strnum = 0;
      const shortMovieStrNum = 400;

      let agreeresults = [];
      let disagreeresults = [];
      if (debug)
        console.log(
          "-★★------------------------------------------------------------"
        );
      //■■■■■■■■■■■■■　文章の分割（カンマ区切りで文分割）　■■■■■■■■■■■■■■■

      for (let i = 0; i < 10; i++) {
        //console.log(i);
        try {
          agreeresults.push(agreelons[i].split("、")); // 「,」 カンマ区切りで分割して配列に格納
          agreeresults[i].splice(0, 1);
          agreeresults[i].map((item, index) => {
            if (item.trim()) {
              //console.log("agree", item.trim());
              strnum = strnum + item.trim().length + 4;
              allgen.push({
                index: allindex,
                vocal: "youmu",
                text: item.trim(),
                isShortMovie: strnum < shortMovieStrNum,
              });
              allindex = allindex + 1;
            }
          });
        } catch {
          //console.log("スライスできなかったかな");
          break;
        }
        try {
          disagreeresults.push(disagreelons[i].split("、")); // 「,」 カンマ区切りで分割して配列に格納
          disagreeresults[i].splice(0, 1);
          disagreeresults[i].map((item, index) => {
            if (item.trim()) {
              //console.log("disagree", item.trim());
              strnum = strnum + item.trim().length + 4;
              allgen.push({
                index: allindex,
                vocal: "reimu",
                text: item.trim(),
                isShortMovie: strnum < shortMovieStrNum,
              });
              allindex = allindex + 1;
            }
          });
        } catch {
          //console.log("スライスできなかったかな");
          break;
        }
      }

      setGenko(allgen);

      createIsMovieShort(strnum < shortMovieStrNum);
      setMovieStage({ ...movieStage, 4: "完" });
      //自動生成フラグ
      if (props.autoCreateMovie.genCreate) {
        props.setAutoCreateMovie({
          ...props.autoCreateMovie,
          genCreate: false,
        });
        //useEffectが動く
      }
      return allgen;
    } catch (error) {
      alert(
        error + "\n文字列が想定した形式でないようです\n二度と押さないでください"
      );
      return false;
    }
  };

  const createExplain = (inputtext) => {
    let EText = "";
    EText = EText + "【AI vs AI】" + editDatas.claim + "?\n";
    EText = EText + "------------------\n";
    EText = EText + inputtext;
    EText = EText + "\n";
    EText = EText + "\n";
    EText = EText + "#shorts #chatgpt4 #lonpa\n";
    EText = EText + "https://lonpa.net/Lonpa/" + editDatas.id + "\n";
    EText = EText + "\n";
    EText = EText + "■ツール\n";
    EText = EText + "Remotion\n";
    EText = EText + "https://www.remotion.dev/\n";
    EText = EText + "\n";
    EText = EText + "AquesTalk10 ・ AqKanji2Koe\n";
    EText = EText + "https://www.a-quest.com/download.html\n";
    EText = EText + "\n";
    EText = EText + "立ち絵\n";
    EText = EText + "http://www.nicotalk.com/charasozai_yk.html\n";
    EText = EText + "\n";
    EText = EText + "BGM\n";
    EText = EText + "https://www.d-elf.com/archives/13079.html\n";
    EText = EText + "\n";
    return EText;
  };

  const createMovieSettingText = (nextMovieSetting) => {
    let MSText = "movie,set1,set2,set3,set4,set5\n";
    MSText = MSText + editDatas.id + ",";
    MSText =
      MSText +
      nextMovieSetting.set1 +
      "," +
      nextMovieSetting.set2 +
      "," +
      nextMovieSetting.set3 +
      "," +
      nextMovieSetting.set4 +
      "," +
      nextMovieSetting.set5;
    setMovieSettingText(MSText);

    if (props.autoCreateMovie.settingCreate) {
      props.setAutoCreateMovie({
        ...props.autoCreateMovie,
        settingCreate: false,
      });
      //useEffectが動く
    }
    return MSText;
  };

  //意見の文章をいい感じに改行するためのコード
  const maketext = (text, flag) => {
    if (text) {
      const locale = "ja-JP";

      const segmenter = new Intl.Segmenter(locale, { granularity: "word" });
      const splittedText = Array.from(segmenter.segment(text)).map(
        (s) => s.segment
      );

      let countwordlen = 0;
      let countlinelen = 1;
      let createText = "";
      splittedText.map((text) => {
        countwordlen = countwordlen + text.length;
        if (countwordlen > 8) {
          if (text.length == countwordlen) {
            createText = createText + text;
          } else {
            countwordlen = text.length;
            createText = createText + "\n" + text;
            countlinelen = countlinelen + 1;
          }
        } else {
          createText = createText + text;
        }
      });
      if (flag) {
        if (countlinelen < 3) {
          createText = "\n\n\n" + createText;
        } else if (countlinelen < 4) {
          createText = "\n\n" + createText;
        } else if (countlinelen < 5) {
          createText = "\n" + createText;
        }
      }

      return createText;
      //makeone(createText);
    }
  };

  const createOutdata = async (hira, key) => {
    console.log("平文 : " + hira);
    const out1 = AES.encrypt(hira, key);
    console.log("暗号文 : " + out1.toString());
    const out2 = AES.decrypt(out1.toString(), key).toString(enc.Utf8);
    console.log("復元文 : " + out2);
    const out3 = AES.decrypt(hira, key).toString(enc.Utf8);
    console.log("復元文2 : " + out3);
    setOutdata(out1.toString());
  };

  const uploadData01 = async () => {
    setSaveStatus({ ...saveStatus, 1: "保存中" });
    if (movieStringUpload(editDatas.id, explainvideo, "explain.txt")) {
      setSaveStatus({ ...saveStatus, 1: "保存済み" });
    } else {
      console.log("エラーです");
      props.stopAuto();
      setSaveStatus({ ...saveStatus, 1: "エラー" });
    }
  };

  const uploadData02 = async () => {
    setSaveStatus({ ...saveStatus, 2: "保存中" });
    await movieStringUpload(editDatas.id, genkocsv, "genko.csv");

    setIsflagone(true);
    setIsflagtwo(true);
    setIsflagthree(isShort);

    await createPickupTransaction(editDatas.id, {
      isflagone: true,
      isflagtwo: true,
      isflagthree: isShort,
      isflagfour: isflagfour,
      isflagfive: isflagfive,
      claim: editDatas.claim,
      discussId: 9999,
    }).then((newrandId) => {
      setRandId(newrandId);
      setSaveStatus({ ...saveStatus, 2: "保存済み" });
      setMovieStage({ ...movieStage, 10: "完" });
    });
  };

  const uploadData03 = async () => {
    setSaveStatus({ ...saveStatus, 3: "保存中" });
    await movieStringUpload(editDatas.id, movieSettingText, "movieSetting.csv");
    setSaveStatus({ ...saveStatus, 3: "保存済み" });
  };

  const uploadData04 = async () => {
    //LonpaPictureの保存ボタンから開始
    setSaveStatus({ ...saveStatus, 4: "保存中" });
    await moviePictureUpload(editDatas.id, moviepng, "100.png");
    setSaveStatus({ ...saveStatus, 4: "保存済み" });
  };

  const uploadDatas = async () => {
    await uploadData01();
    await uploadData02();
    await uploadData03();
    await uploadData04();
    setSaveStatus({
      1: "保存済み",
      2: "保存済み",
      3: "保存済み",
      4: "保存済み",
      5: "保存済み",
    });

    //自動生成フラグ
    if (props.autoCreateMovie.saveCreate) {
      props.setAutoCreateMovie({
        ...props.autoCreateMovie,
        saveCreate: false,
      });
      //useEffectが動く
    }
  };

  const createCsv = (inputgenko) => {
    //console.log("csv作成");
    let csvDatas = "index,vocal,text\n";

    inputgenko.map((item) => {
      const csvOneData = item.index + "," + item.vocal + "," + item.text + "\n";
      csvDatas = csvDatas + csvOneData;
    });
    setGenkocsv(csvDatas);
    setMovieStage({ ...movieStage, 5: "完" });
    if (props.autoCreateMovie.csvCreate) {
      props.setAutoCreateMovie({
        ...props.autoCreateMovie,
        csvCreate: false,
      });
      //useEffectが動く
    }
  };

  const createMovie = () => {
    try {
      const functions = getFunctions();
      const movieCreate = httpsCallable(functions, "movieCreate");
      movieCreate({ lonpaID: editDatas.id }).then((result) => {
        if (result.data) {
          alert(error + "\n作成成功おめでとう");
          console.log("成功");
          return true;
        } else {
          console.log("失敗");
          return false;
        }
      });
    } catch (error) {
      alert(error + "\nエラー#0004、二度と押さないでください");
      return false;
    }
  };

  const createLog = () => {
    console.log("createlog");
    try {
      const functions = getFunctions();
      const logCreate = httpsCallable(functions, "logtest");
      logCreate().then((result) => {
        console.log("send");
        if (result.data) {
          alert(error + "\n作成成功おめでとう");
          console.log("成功");
          return true;
        } else {
          console.log("失敗");
          return false;
        }
      });
    } catch (error) {
      alert(error + "\nエラー#0005、二度と押さないでください");
      return false;
    }
  };

  return (
    <div style={{ backgroundColor: props.bgColor.back01 }}>
      <div style={{ height: "6vh" }}></div>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ backgroundColor: "#fff" }}>
            <h2 id="transition-modal-title" align="center">
              動画作成ツール
            </h2>
          </Grid>
          <Grid item xs={12} style={{ backgroundColor: "#fff" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="API実行で料金発生することを理解し、自己負担することに承諾します"
              />
            </FormGroup>

            {checked ? (
              <ToggleButtonGroup
                value={togglenum}
                exclusive
                onChange={handleFormat}
                align="center"
                style={{
                  width: "100%",
                  backgroundColor: "#CCCCFF",
                  lineHeight: "0px",
                }}
              >
                <ToggleButton
                  value={0}
                  aria-label={0}
                  style={{
                    width: `calc(100% / 2)`,
                    border: 0,
                    padding: "0px",
                    fontSize: 20,
                  }}
                >
                  動画
                  {/* 拡大 */}
                </ToggleButton>
                <ToggleButton
                  value={1}
                  aria-label={1}
                  style={{
                    width: `calc(100% / 2)`,
                    border: 0,
                    padding: "0px",
                    fontSize: 20,
                  }}
                >
                  フラグ管理
                  {/* 拡大 */}
                </ToggleButton>
              </ToggleButtonGroup>
            ) : (
              <></>
            )}
          </Grid>
          {isopenone ? (
            <>
              <Grid item xs={12} style={{ backgroundColor: "#fff" }}>
                <div align="center">
                  {editDatas.id}
                  {" / "}
                  {editDatas.claim}
                </div>
                <br />
                <Accordion expanded={expanded === "panel1"}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => {
                      setExpanded(expanded === "panel1" ? false : "panel1");
                    }}
                  >
                    <Typography sx={{ width: "80%", flexShrink: 0 }}>
                      1:つかいかた
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      {movieStage[1]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Grid item xs={12} style={{ backgroundColor: "#fff" }}>
                        <br />
                        まだ書いてないよ
                        <br />
                        <br />
                        <br />
                      </Grid>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "panel2"}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                    onClick={() => {
                      setExpanded(expanded === "panel2" ? false : "panel2");
                    }}
                  >
                    <Typography sx={{ width: "80%", flexShrink: 0 }}>
                      2:生成指示文の作成
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      {movieStage[2]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Button
                        color="primary"
                        onClick={() => {
                          createRapInput(false);
                        }}
                        style={{ fontSize: 20, fontWeight: "bold" }}
                      >
                        RAP生成用の文書の作成
                      </Button>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          copyToClipboard(fourTextShow);
                        }}
                      >
                        <ContentCopyIcon fontSize="inherit" />
                      </IconButton>
                      <TextField
                        fullWidth
                        label="(4)動画原稿生成:元"
                        multiline
                        maxRows={10}
                        minRows={5}
                        value={fourTextShow}
                        onChange={(e) => setFourTextShow(e.target.value)}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "panel3"}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    onClick={() => {
                      setExpanded(expanded === "panel3" ? false : "panel3");
                    }}
                  >
                    <Typography sx={{ width: "80%", flexShrink: 0 }}>
                      3:RAP文書元生成
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      {movieStage[3]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Button
                        color="primary"
                        onClick={() => {
                          setPointPattarn("makeManuscript");
                          setMovieUsePointmodalopen(true);
                        }}
                        style={{ fontSize: 20, fontWeight: "bold" }}
                      >
                        RAP生成(chatgpt3.5)
                      </Button>
                      <TextField
                        fullWidth
                        label="(5)動画原稿"
                        multiline
                        maxRows={10}
                        minRows={5}
                        value={fiveTextShow}
                        onChange={(e) => setFiveTextShow(e.target.value)}
                      />
                      <br />
                      区切り文字確認
                      <br />
                      <TextField
                        label="賛成"
                        value={nameAgree}
                        onChange={(e) => setNameAgree(e.target.value)}
                      />
                      <br />
                      <TextField
                        label="反対"
                        value={nameDisagree}
                        onChange={(e) => setNameDisagree(e.target.value)}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "panel4"}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                    onClick={() => {
                      setExpanded(expanded === "panel4" ? false : "panel4");
                    }}
                  >
                    <Typography sx={{ width: "80%", flexShrink: 0 }}>
                      4:セリフ表生成
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      {movieStage[4]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Button
                        color="primary"
                        onClick={() => {
                          createGen2(fiveTextShow);
                        }}
                        style={{ fontSize: 20, fontWeight: "bold" }}
                      >
                        RAP文章元からセリフ表変換
                      </Button>
                      <br />
                      <Button
                        color="primary"
                        onClick={() => {
                          downloadGen();
                        }}
                        style={{ fontSize: 20, fontWeight: "bold" }}
                        disable
                      >
                        保存データから読み込み
                      </Button>
                      <MakeCsv
                        genko={genko}
                        setGenko={setGenko}
                        setGenkocsv={setGenkocsv}
                        setChangeText={setChangeText}
                        setChangeVocal={setChangeVocal}
                        createIsMovieShort={createIsMovieShort}
                        changeText={changeText}
                        changeVocal={changeVocal}
                        remakeCountIncriment={remakeCountIncriment}
                        remakeCount={remakeCount}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "panel5"}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                    onClick={() => {
                      setExpanded(expanded === "panel5" ? false : "panel5");
                    }}
                  >
                    <Typography sx={{ width: "80%", flexShrink: 0 }}>
                      5:セリフ表csv作成
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      {movieStage[5]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {movieStage[4] == "未" ? (
                        <Button
                          color="primary"
                          disabled
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          csv作成
                        </Button>
                      ) : (
                        <>
                          <Button
                            color="primary"
                            onClick={() => {
                              createCsv(genko);
                            }}
                            style={{ fontSize: 20, fontWeight: "bold" }}
                          >
                            csv作成
                          </Button>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => {
                              copyToClipboard(genkocsv);
                            }}
                          >
                            <ContentCopyIcon fontSize="inherit" />
                          </IconButton>
                        </>
                      )}

                      <br />
                      <TextField
                        fullWidth
                        multiline
                        maxRows={5}
                        minRows={5}
                        label="genkocsv"
                        value={genkocsv}
                        onChange={(e) => setGenkocsv(e.target.value)}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "panel6"}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header"
                    onClick={() => {
                      setExpanded(expanded === "panel6" ? false : "panel6");
                    }}
                  >
                    <Typography sx={{ width: "80%", flexShrink: 0 }}>
                      6:動画設定作成
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      {movieStage[6]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <b>サイズ</b>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue="1"
                        onChange={(e) => {
                          setMovieSetting({
                            ...movieSetting,
                            set1: e.target.value,
                          });
                          createMovieSettingText({
                            ...movieSetting,
                            set1: e.target.value,
                          });
                          setMovieStage({ ...movieStage, 6: "完" });
                        }}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="スマホ(1080✖️1350)"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="youtube(1920x1080)"
                        />
                      </RadioGroup>
                      <br />
                      <b>タイプ</b>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue="1"
                        onChange={(e) => {
                          setMovieSetting({
                            ...movieSetting,
                            set2: e.target.value,
                          });
                          createMovieSettingText({
                            ...movieSetting,
                            set2: e.target.value,
                          });
                          setMovieStage({ ...movieStage, 6: "完" });
                        }}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="スタイリッシュ"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="落ち着き"
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label="ジョーク"
                        />
                      </RadioGroup>
                      <br />
                      <b>音楽</b>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue="1"
                        onChange={(e) => {
                          setMovieSetting({
                            ...movieSetting,
                            set3: e.target.value,
                          });
                          createMovieSettingText({
                            ...movieSetting,
                            set3: e.target.value,
                          });
                          setMovieStage({ ...movieStage, 6: "完" });
                        }}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="スタイリッシュ"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="落ち着き"
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label="ジョーク"
                        />
                      </RadioGroup>

                      <br />
                      <b>動画タイプ</b>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={isShort}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="ショート"
                          disabled
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="ロング"
                          disabled
                        />
                      </RadioGroup>

                      <br />
                      <TextField
                        fullWidth
                        multiline
                        maxRows={3}
                        minRows={2}
                        label="MovieSettingText"
                        value={movieSettingText}
                        onChange={(e) => setMovieSettingText(e.target.value)}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "panel7"}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel7bh-content"
                    id="panel7bh-header"
                    onClick={() => {
                      setExpanded(expanded === "panel7" ? false : "panel7");
                    }}
                  >
                    <Typography sx={{ width: "80%", flexShrink: 0 }}>
                      7:使用画像作成
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      {movieStage[7]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {" "}
                      <LonpaPictures
                        picType={100}
                        picText={maketext(editDatas.claim, 1)}
                        picText2={""}
                        picText3={""}
                        picText4={""}
                        url={"https://lonpa.net"}
                        setMoviepngAndFlag={setMoviepngAndFlag}
                        autoCreateFlag={autoCreateFlag}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "panel8"}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel8bh-content"
                    id="panel8bh-header"
                    onClick={() => {
                      setExpanded(expanded === "panel8" ? false : "panel8");
                    }}
                  >
                    <Typography sx={{ width: "80%", flexShrink: 0 }}>
                      8:文章生成元文章生成
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      {movieStage[8]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Button
                        color="primary"
                        style={{ fontSize: 20, fontWeight: "bold" }}
                        onClick={() => {
                          createExplainvideoInput(false);
                        }}
                      >
                        テキスト生成
                      </Button>

                      <TextField
                        fullWidth
                        multiline
                        maxRows={5}
                        minRows={5}
                        label="explainvideo"
                        value={explainvideoInput}
                        onChange={(e) => setExplainvideoInput(e.target.value)}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "panel9"}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel9bh-content"
                    id="panel9bh-header"
                    onClick={() => {
                      setExpanded(expanded === "panel9" ? false : "panel9");
                    }}
                  >
                    <Typography sx={{ width: "80%", flexShrink: 0 }}>
                      9:文章生成
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      {movieStage[9]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Button
                        color="primary"
                        style={{ fontSize: 20, fontWeight: "bold" }}
                        onClick={() => {
                          setPointPattarn("makeSNS");
                          setMovieUsePointmodalopen(true);
                        }}
                      >
                        テキスト生成(gpt)
                      </Button>

                      <TextField
                        fullWidth
                        multiline
                        maxRows={5}
                        minRows={5}
                        label="explainvideo"
                        value={explainvideo}
                        onChange={(e) => setExplainvideo(e.target.value)}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "panel10"}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel10bh-content"
                    id="panel10bh-header"
                    onClick={() => {
                      setExpanded(expanded === "panel10" ? false : "panel10");
                    }}
                  >
                    <Typography sx={{ width: "80%", flexShrink: 0 }}>
                      10:保存
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      {movieStage[10]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <b>動画説明</b>
                      {false ? (
                        <Button
                          color="primary"
                          disabled
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          保存
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          style={{ fontSize: 20, fontWeight: "bold" }}
                          onClick={() => {
                            uploadData01();
                          }}
                        >
                          保存
                        </Button>
                      )}
                      {saveStatus[1]}
                      <br />
                      <b>セリフ表csv</b>
                      {movieStage[5] == "未" ? (
                        <Button
                          color="primary"
                          disabled
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          保存
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          style={{ fontSize: 20, fontWeight: "bold" }}
                          onClick={() => {
                            uploadData02();
                          }}
                        >
                          保存
                        </Button>
                      )}
                      {saveStatus[2]}
                      <br />
                      <b>動画設定</b>
                      {movieStage[6] == "未" ? (
                        <Button
                          color="primary"
                          disabled
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          保存
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          style={{ fontSize: 20, fontWeight: "bold" }}
                          onClick={() => {
                            uploadData03();
                          }}
                        >
                          保存
                        </Button>
                      )}
                      {saveStatus[3]}
                      <br />
                      <b>画像素材</b>
                      {movieStage[7] == "未" ? (
                        <Button
                          color="primary"
                          disabled
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          保存
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          style={{ fontSize: 20, fontWeight: "bold" }}
                          onClick={() => {
                            uploadData04();
                          }}
                        >
                          保存
                        </Button>
                      )}
                      {saveStatus[4]}
                      <br />
                      <b>★</b>
                      {movieStage[5] == "完" &&
                      movieStage[6] == "完" &&
                      movieStage[7] == "完" ? (
                        <Button
                          color="primary"
                          style={{ fontSize: 20, fontWeight: "bold" }}
                          onClick={() => {
                            uploadDatas();
                          }}
                        >
                          まとめて保存
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          style={{ fontSize: 20, fontWeight: "bold" }}
                          disabled
                        >
                          まとめて保存
                        </Button>
                      )}
                      {saveStatus[5]}
                      <br />
                      <br />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </>
          ) : (
            <></>
          )}

          {isopentwo ? (
            <>
              <Grid item xs={12} style={{ backgroundColor: "#eee" }}>
                randId:{randId}
                <br />
                <Button
                  color="primary"
                  onClick={() => {
                    props.stopAuto();
                    navigate("/MovieState/");
                  }}
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  ステート画面へ移動
                </Button>
                <br />
                {!isflagone ? (
                  <>
                    <Button
                      color="primary"
                      onClick={() => {
                        setPointPattarn("autocreate");
                        setMovieUsePointmodalopen(true);
                      }}
                      style={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      オートクリエイトを実行
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      color="primary"
                      style={{ fontSize: 20, fontWeight: "bold" }}
                      disabled={true}
                    >
                      オートクリエイトを実行
                    </Button>
                  </>
                )}
                <br />
                <br />
                <br />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isflagone}
                        onChange={(e) => {
                          if (isflagone) {
                            setIsflagone(false);
                          } else {
                            setIsflagone(true);
                          }
                        }}
                      />
                    }
                    label="着手"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isflagtwo}
                        onChange={() => {
                          if (isflagtwo) {
                            setIsflagtwo(false);
                          } else {
                            setIsflagtwo(true);
                          }
                        }}
                      />
                    }
                    label="動画設定生成"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isflagthree}
                        onChange={() => {
                          if (isflagthree) {
                            setIsflagthree(false);
                          } else {
                            setIsflagthree(true);
                          }
                        }}
                      />
                    }
                    label="ショート動画用に設定"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isflagfour}
                        onChange={() => {
                          if (isflagfour) {
                            setIsflagfour(false);
                          } else {
                            setIsflagfour(true);
                          }
                        }}
                      />
                    }
                    label="動画ビルド"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isflagfive}
                        onChange={() => {
                          if (isflagfive) {
                            setIsflagfive(false);
                          } else {
                            setIsflagfive(true);
                          }
                        }}
                      />
                    }
                    label="未設定フラグ"
                  />
                </FormGroup>
                <Button
                  disabled={!isflagone}
                  color="primary"
                  onClick={() => {
                    createPickupTransaction(editDatas.id, {
                      isflagone: isflagone,
                      isflagtwo: isflagtwo,
                      isflagthree: isflagthree,
                      isflagfour: isflagfour,
                      isflagfive: isflagfive,
                      claim: editDatas.claim,
                      discussId: 9999,
                    }).then((newrandId) => {
                      setRandId(newrandId);
                    });
                  }}
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  進捗フラグの手動更新
                </Button>
                <br />
                <br />
                {!isflagfour && isflagthree ? (
                  <>
                    <Button
                      disabled={!isflagone}
                      color="primary"
                      onClick={() => {
                        createPickupTransaction(editDatas.id, {
                          isflagone: isflagone,
                          isflagtwo: isflagtwo,
                          isflagthree: isflagthree,
                          isflagfour: true,
                          isflagfive: isflagfive,
                          claim: editDatas.claim,
                          discussId: 9999,
                        }).then((newrandId) => {
                          setRandId(newrandId);
                          setPointPattarn("makemovieonly");
                          setMovieUsePointmodalopen(true);
                        });
                      }}
                      style={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      【！危険！】動画生成【！危険！】
                    </Button>

                    <br />
                    <br />
                    <Button
                      disabled={!isflagone}
                      color="primary"
                      onClick={() => {
                        createLog();
                      }}
                      style={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      【！危険！】ログ作成テスト【！危険！】
                    </Button>
                  </>
                ) : (
                  <>
                    {isflagfive ? (
                      <>
                        <Button
                          disabled={!isflagone}
                          color="primary"
                          onClick={() => {
                            window.open("/MovieShow/" + editDatas.id, "_blank");
                          }}
                          style={{ fontSize: 20, fontWeight: "bold" }}
                        >
                          動画を見る
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Grid>
            </>
          ) : (
            <></>
          )}

          {isopenthree ? (
            <>
              <Grid item xs={12} style={{ backgroundColor: "#eee" }}>
                <TextField
                  label="平"
                  value={hira}
                  onChange={(e) => setHira(e.target.value)}
                />
                <br />
                <TextField
                  label="鍵"
                  value={annkey}
                  onChange={(e) => setAnnkey(e.target.value)}
                />
                <br />
                <Button
                  color="primary"
                  onClick={() => {
                    createOutdata(hira, annkey);
                  }}
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  シークレット
                </Button>
                <br />
                <TextField label="暗" value={outdata} />
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Container>
      {urlList.type1.link}

      <div style={{ height: "6vh" }}></div>
      {movieUsePointmodalopen ? (
        <MovieUsePointModal
          open={movieUsePointmodalopen}
          setOpen={setMovieUsePointmodalopen}
          pointPattarn={pointPattarn}
          executeAutomake={executeAutomake}
          executeMakeManuscript={executeMakeManuscript}
          executeMakeSNS={executeMakeSNS}
          executeMakeMovieOnly={executeMakeMovieOnly}
          id={editDatas.id}
        />
      ) : (
        <></>
      )}
      <LodingModal open={lodingmodalopen} setOpen={setLodingmodalopen} />
    </div>
  );
}
